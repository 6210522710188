





































import Vue from "vue";
import ConfirmDialog from "../../../components/ConfirmDialog.vue";

import { Collection } from "@/store/types";

export default Vue.extend({
  name: "CollTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    showDeleteDialog: false,
    collToDelete: null as unknown | Collection,
    headers: [
      {
        text: "Rank",
        align: "start",
        value: "rank",
        width: "100"
      },
      {
        text: "Imagem",
        value: "picture",
        sortable: false,
        width: "100"
      },
      {
        text: "Título",
        align: "start",
        value: "name",
      },
      {
        text: "Status",
        value: "info",
        align: "center",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  methods: {
    showDelDialog(item: Collection): void {
      this.showDeleteDialog = true
      this.collToDelete = item;
    },

    onEdit(item: Collection): void {
      this.$emit("onEdit", item)
    },

    onDelete(): void {
      this.$emit("onDelete", this.collToDelete)
      this.showDeleteDialog = false
    }
  }
})
