

























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import CollForm from "./components/CollForm.vue";
import CollTable from "./components/CollTable.vue";

import { Collection } from "@/store/types";


export default Vue.extend({
  name: "Collections",

  components: {
    FullScreenDialog,
    CollForm,
    CollTable
  },

  data: () => ({
    fullScreenDialog: false,
    collection: null as unknown | Collection
  }),

  computed: {
    ...mapGetters({
      collections: "collections/getCollections",
    })
  },

  mounted() {
    this.$store.dispatch("collections/fetch");
  },

  methods: {
    editCol(collection: Collection): void {
      this.collection = collection;
      this.openFullScreenDialog();
    },

    deleteCol(collection: Collection): void {
      this.$store.dispatch("collections/delete", collection);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.collection = null;
    },
  }
})
